<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'CustomerList',
              }"
              >會員管理</b-breadcrumb-item
            >
            <b-breadcrumb-item active>顧客會員批次上傳</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="col-12 col-xl-6 d-flex align-items-center">
        <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">顧客會員批次上傳</h4>
      </div>
      <div class="row my-3">
        <div class="col-12 col-xl-3">
          <div>請選擇通路</div>
          <b-form-select
            v-model="selectedMerchantID"
            :options="merchants"
            :disabled="isFetchMerchants"
          ></b-form-select>
        </div>
        <div class="col-12 col-xl-3 offset-1 d-flex align-items-center">
          <div class="row">
            <div class="col-12">請選擇會員識別欄位</div>
            <b-form-checkbox
              v-for="unique_by in uniqueByList"
              :key="unique_by.id"
              v-model="form.unique_by"
              :value="unique_by.id"
              class="mr-2"
            >
              {{ unique_by.name }}
            </b-form-checkbox>
          </div>
        </div>
        <div
          class="
            col-12 col-xl-4
            d-flex
            flex-column
            align-items-start align-items-xl-center
          "
        >
          <b-button variant="primary"
            @click="getSampleExcelFile(getModuleConfig('customer', 'sample_file.customer_import_xls') ,'/excel/customer_list.xlsx')"
                >下載範例檔案</b-button
          >
          <div class="row mt-2">
            <b-form-file
              id="fileInput"
              v-model="form.file"
              plain
              hidden
              @change="handleUploadFile"
            ></b-form-file>
            <b-button
              class="flex-shrink-0 mb-2 mb-xl-0"
              variant="primary"
              tag="label"
              for="fileInput"
            >
              請選擇檔案</b-button
            >
          </div>
          <b-button class="mt-2" variant="primary" @click="handleSubmitFile"
            >確定上傳</b-button
          >
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="accordion" role="tablist">
            <b-card
              no-body
              class="mb-1"
              v-for="(key, index) in Object.keys(results)"
              :key="index"
            >
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  v-if="key === 'failed'"
                  block
                  v-b-toggle.accordion-0
                  variant="info"
                  >失敗：{{ results.failed.length }}筆</b-button
                >
                <b-button
                  v-if="key === 'created'"
                  block
                  v-b-toggle.accordion-1
                  variant="info"
                  >新增：{{ results.created.length }}筆</b-button
                >
                <b-button
                  v-if="key === 'existed'"
                  block
                  v-b-toggle.accordion-2
                  variant="info"
                  >更新：{{ results.existed.length }}筆</b-button
                >
              </b-card-header>
              <b-collapse
                :id="`accordion-${index}`"
                visible
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-table
                    striped
                    hover
                    responsive
                    :items="results[key]"
                    :fields="fields"
                    :busy="isLoading"
                  >
                    <template #table-busy>
                      <div class="text-center my-2">
                        <b-spinner class="align-middle"></b-spinner>
                      </div>
                    </template>
                  </b-table>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import customerApi from "../../../apis/customer";
import merchantApi from "../../../apis/merchant";
import { getSampleExcelFile } from "@/utils/dashboardUtils";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { mapState, mapGetters } from "vuex";

export default {
  data() {
    return {
      isFetchMerchants: false,
      isLoading: false,
      selectedMerchantID: null,
      merchants: [{ value: null, text: "請選擇" }],
      form: {
        file: null,
        unique_by: [],
      },
      results: {
        failed: [],
        created: [],
        existed: [],
      },
      customers: [],
      uniqueByList: [
        { id: "mobile_phone", name: "手機" },
        { id: "birthday", name: "生日" },
        { id: "email", name: "Email" },
        { id: "outer_code", name: "Outer Code" },
      ],
      fields: [
        {
          key: "row",
          label: "第幾列",
        },
        {
          key: "name",
          label: "顯示名稱",
        },
        {
          key: "outer_code",
          label: "會員外部編號",
        },
        {
          key: "mobile_phone",
          label: "手機",
        },
        {
          key: "email",
          label: "Email",
        },
        {
          key: "birth",
          label: "生日",
        },
        {
          key: "sexual",
          label: "性別",
        },
        {
          key: "level_code",
          label: "會員等級代號",
        },
        {
          key: "level_name",
          label: "會員等級名稱",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      getModuleConfig: "dashboardModule/getConfig",
    }),
    ...mapState("general", {
      defaultMerchantType: (state) => state.defaultMerchantType,
    }),
  },

  created() {
    this.getMerchants();

    if (this.selectedMerchantID) {
      this.getCustomerList();
    }
  },

  watch: {
    "form.is_binded"() {
      this.getCustomerList();
    },
  },

  methods: {
    getSampleExcelFile,
    async getMerchants() {
      this.isFetchMerchants = true;
      const { data } = await merchantApi.list();
      this.isFetchMerchants = false;

      this.merchants = [
        { value: null, text: "請選擇" },
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: merchant.type_name
              ? `${merchant.name} (${merchant.type_name})`
              : merchant.name,
          };
        }),
      ];

      const defaultMerchant = data.data?.find((merchant) => merchant.type === this.defaultMerchantType) ??
        data.data?.find((merchant) => merchant.type === "LINE") ;
      this.selectedMerchantID = defaultMerchant.id;
    },

    handleBack() {
      this.$router.push({ name: "CustomerList" });
    },

    handleUploadFile(e) {
      const data = new FormData();

      data.append("file", new Blob(["test payload"], { type: "text/csv" }));

      console.log("handleUploadFile", e, e.target.files);
    },

    async handleSubmitFile() {
      try {
        if (!this.selectedMerchantID) {
          this.$swal.fire({
            icon: "error",
            title: "請選擇通路",
          });
          return;
        }
        if (this.form.unique_by.length === 0) {
          this.$swal.fire({
            icon: "error",
            title: "請勾選會員識別欄位",
          });
          return;
        }
        this.isLoading = true;
        const fd = new FormData();
        fd.append("file", this.form.file);
        fd.append("unique_by", JSON.stringify(this.form.unique_by));

        const { data } = await customerApi.importCustomers(
          this.selectedMerchantID,
          fd
        );
        this.results = {
          failed: data.data.failed,
          created: data.data.created,
          existed: data.data.existed,
        }
        const moduleMapping = this.getModuleConfig('customer', 'sample_file.customer_import_xls.mapping');
        if (moduleMapping) {
          // 去替換掉如果有被覆蓋的內容
          moduleMapping.forEach((mapping) => {
            const field = this.fields?.find((field) => field.key == mapping.key);
            if (field) {
              field.label = mapping.text;
              field.key = mapping.text;
            } else {
              this.fields.push({
                key: mapping.text,
                label: mapping.text,
              });
            }
          });
        }
        this.fields.forEach((field) => {
            field.key = field.label;
        });
        this.isLoading = false;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style></style>
